// import React, { useState } from 'react';
// import CourseSection from './CourseSection';
// import c1 from '../../assets/images/Python.png';
// import c2 from '../../assets/images/slider1.png';
// import Slider from "./Slider"
// import './CourseLayout.css';
// import Header from '../../components/Header/Header';
// import Footer from '../../components/Footer/Footer';
// import { IoChevronForwardCircle } from "react-icons/io5";

// const CourseLayout = () => {
//   const [activeSection, setActiveSection] = useState(null);
  
//   const courseContent = {
//     'Python Basics': {
//       image: c1,
//       topics: ['Variables & Data Types', 'Control Flow', 'Loops', 'Functions']
//     },
//     'Python Intermediate': {
//       image: c1,
//       topics: ['Object Oriented Programming', 'Error Handling', 'File Handling', 'Modules']
//     },
//     'Python Advanced': {
//       image: c1,
//       topics: ['Decorators', 'Generators', 'Threading', 'Web Scraping']
//     }
//   };

//   const handleSectionClick = (section) => {
//     setActiveSection(activeSection === section ? null : section);
//   };

//   return (
//     <>
//     <Header/>
//     <Slider/>
//     <div className="course_container">
//       <div className="course_left_panel">
//         <h2 className="course_heading">Python Course Content</h2>
//         {Object.entries(courseContent).map(([section, { topics }]) => (
//           <CourseSection
//             key={section} 
//             section={section}
//             topics={topics}
//             isActive={activeSection === section}
//             onClick={() => handleSectionClick(section)}
//           />
//         ))}
//       </div>
      
//       <div className="course_right_panel">
//         <div className="course_image_container">
//           <img 
//             src={activeSection ? courseContent[activeSection].image : c1} 
//             alt="Course Section" 
//             className="course_image"
//           />
//         </div>
//     <div className="course_buttons">
//       <button className="course_button course_video_btn">Video</button>
//       <button className="course_button course_quiz_btn">Quiz</button>
//       <button className="course_button course_plan_btn">Learning Plan</button>
//       <button className="course_button course_bookmark_btn">Bookmark</button>
//       <button className="course_button course_share_btn">Share</button>
//     </div>
//       </div>
//     </div>
//     <Footer/>
//     </>
//   );
// };

// export default CourseLayout;

import React, { useState } from 'react';
import CourseSection from './CourseSection';
import c1 from '../../assets/images/Python.png';
import c2 from '../../assets/images/slider1.png';
import Slider from "./Slider"
import './CourseLayout.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { IoChevronForwardCircle } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

const CourseLayout = () => {
  const [activeSection, setActiveSection] = useState(null);
  const navigate = useNavigate();
  
  const courseContent = {
    'Python Basics': {
      image: c1,
      topics: ['Variables & Data Types', 'Control Flow', 'Loops', 'Functions'],
      path: '/python-basics'
    },
    'Python Intermediate': {
      image: c1,
      topics: ['Object Oriented Programming', 'Error Handling', 'File Handling', 'Modules'],
      path: '/python-intermediate'
    },
    'Python Advanced': {
      image: c1,
      topics: ['Decorators', 'Generators', 'Threading', 'Web Scraping'],
      path: '/python-advanced'
    }
  };

  const handleSectionClick = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  const handleGetStarted = () => {
    if (activeSection && courseContent[activeSection]) {
      navigate(courseContent[activeSection].path);
    }
  };

  return (
    <>
      <Header/>
      <Slider/>
      <div className="course_container">
        <div className="course_left_panel">
          <h2 className="course_heading">Python Course Content</h2>
          {Object.entries(courseContent).map(([section, { topics }]) => (
            <CourseSection
              key={section}
              section={section}
              topics={topics}
              isActive={activeSection === section}
              onClick={() => handleSectionClick(section)}
            />
          ))}
        </div>
        
        <div className="course_right_panel">
          <div className="course_image_container">
            <img 
              src={activeSection ? courseContent[activeSection].image : c1} 
              alt="Course Section" 
              className="course_image"
            />
          <button 
  className="course_get_started_btn"
  onClick={handleGetStarted}
>
  <span>GET STARTED</span> 
  <IoChevronForwardCircle className="chevron_icon" />
</button>

          </div>
          <div className="course_buttons">
            <button className="course_button course_video_btn">Video</button>
            <button className="course_button course_quiz_btn">Quiz</button>
            <button className="course_button course_plan_btn">Learning Plan</button>
            <button className="course_button course_bookmark_btn">Bookmark</button>
            <button className="course_button course_share_btn">Share</button>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default CourseLayout;