import React from 'react';
import './Slides.css';

const SlideContent = ({ content, currentSlide }) => {
  return (
    <div className="slide-content">
      <div className="slide-header">
        <div className="course-path">
          <span className="path-item">JavaScript Study I</span>
          <span className="path-separator">›</span>
          <span className="path-item">Strings & Integers</span>
        </div>
        <h1 className="slide-title">{content[currentSlide].title}</h1>
        {content[currentSlide].description && (
          <p className="slide-description">{content[currentSlide].description}</p>
        )}
      </div>
      
      <div className="slide-main">
        <div className="editor-section">
          <div className="editor-header">
            <span className="file-name">script.js</span>
          </div>
          <div className="editor-content">
            {content[currentSlide].code && (
              <pre>
                <code>{content[currentSlide].code}</code>
              </pre>
            )}
          </div>
        </div>
        
        {content[currentSlide].output && (
          <div className="output-section">
            <div className="output-header">
              <span className="output-title">Console</span>
            </div>
            <div className="output-content">
              <pre>
                <code>{content[currentSlide].output}</code>
              </pre>
              {content[currentSlide].explanation && (
                <div className="output-explanation">
                  <span className="explanation-arrow">↑</span>
                  <p>{content[currentSlide].explanation}</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SlideContent;