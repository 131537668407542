import React from 'react';
import Slider from 'react-slick';
import GraduationImage from '../../assets/images/Aboutimage.png'; 
import './Slider.css';

const CourseSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 15000,
    arrows: true,
  };

  return (
    <div className="course_slider">
      <Slider {...settings}>
        {[...Array(5)].map((_, index) => (
          <div className="course_slider-slide" key={index}>
            <div className="course_slider-content">
              <div className="course_slider-text">
                <h1 style={{textAlign:"center"}}>Empowering the world <br />
                    to excel at their <br />
                    Studies
                </h1>
              </div>
              <div className="course_slider-image">
                <img src={GraduationImage} alt="Graduation celebration" />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};



export default CourseSlider;
