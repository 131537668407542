import React from 'react';
import { IoChevronForwardCircle } from "react-icons/io5";

const CourseSection = ({ section, topics, isActive, onClick }) => {
  return (
    <div className="course_section">
      <div 
        className={`course_section_header ${isActive ? 'course_active' : ''}`}
        onClick={onClick}
      >
        <IoChevronForwardCircle style={{ marginRight: '8px' }} /> {/* Icon added here */}
        {section}
      </div>
      {isActive && (
        <div className="course_topics">
          {topics.map((topic) => (
            <div key={topic} className="course_topic">
              {topic}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CourseSection;
