import { useState } from 'react'
import './Faq.css'

function Faq() {
  const [openIndex, setOpenIndex] = useState(null);

  const faqData = [
    {
      question: "How much time do I need to commit each week?",
      answer: "That's totally up to you! You can spend as little or as much time as you want. Remember, practice makes perfect!"
    },
    {
      question: "What if I get stuck on something?",
      answer: "Our support team is always here to help! You can reach out through our help center or community forums."
    },
    {
      question: "How do I know I'm making progress?",
      answer: "You can track your progress through our built-in assessment system and milestone achievements."
    }
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq_container">
      <div className="faq_content">
        <h1 className="faq_title">
          Frequently Asked Questions
          <span className="faq_underline"></span>
        </h1>
        <div className="faq_list">
          {faqData.map((faq, index) => (
            <div key={index} className="faq_item">
              <button 
                className={`faq_question ${openIndex === index ? 'faq_active' : ''}`}
                onClick={() => toggleFAQ(index)}
              >
                {faq.question}
                <span className="faq_icon">
                  {openIndex === index ? '▼' : '▲'}
                </span>
              </button>
              {openIndex === index && (
                <div className="faq_answer">{faq.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="faq_illustration">
        <div className="faq_character"></div>
      </div>
    </div>
  )
}

export default Faq