import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import SlideContent from './SlideContent';
import SlideControls from './SlideControls';
import SlideProgress from './SlideProgress';
import './Slides.css';

const CourseSlides = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { course } = useParams();

  const slideContent = {
    'python-basics': [
      {
        title: "Running JavaScript Code",
        description: "First, we'll see how to run (execute) JavaScript code and print (display) the words in a screen known as the console.",
        code: 'console.log("Hello World");',
        output: "Hello Worldd",
        explanation: "The characters Hello World have been printed to the console."
      },
      {
        title: "Variables",
        description: "Let's learn how to store data in variables!",
        code: `let name = "John";
let age = 25;
console.log(name);
console.log(age);`,
        output: `John
25`,
        explanation: "Variables store data that can be used later in your code."
      },
      {
        title: "String Concatenation",
        description: "You can combine strings using the + operator.",
        code: `let firstName = "John";
let lastName = "Doe";
console.log(firstName + " " + lastName);`,
        output: "John Doe",
        explanation: "The + operator joins (concatenates) strings together."
      }
    ]
  };

  const content = slideContent[course] || [];
  
  const handlePrevious = () => {
    setCurrentSlide(prev => Math.max(0, prev - 1));
  };

  const handleNext = () => {
    setCurrentSlide(prev => Math.min(content.length - 1, prev + 1));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'ArrowRight') handleNext();
    if (e.key === 'ArrowLeft') handlePrevious();
  };

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  if (!content.length) {
    return <div className="error-message">Course content not found!</div>;
  }

  return (
    <div className="slides-container" tabIndex="0">
      <SlideProgress 
        currentSlide={currentSlide} 
        totalSlides={content.length} 
      />
      
      <div className="slide-wrapper">
        <SlideContent 
          content={content} 
          currentSlide={currentSlide} 
        />
      </div>

      <SlideControls 
        currentSlide={currentSlide}
        totalSlides={content.length}
        onPrevious={handlePrevious}
        onNext={handleNext}
      />
    </div>
  );
};

export default CourseSlides;
