import React from 'react';
import './Slides.css';

const SlideProgress = ({ currentSlide, totalSlides }) => {
  const progress = ((currentSlide + 1) / totalSlides) * 100;
  
  return (
    <div className="progress-container">
      <div 
        className="progress-bar" 
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default SlideProgress;